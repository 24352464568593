if (!window.clark) {
  window.clark = {};
}
if (!window.clark.webapp) {
  window.clark.webapp = {};
}

// @see https://clarkteam.atlassian.net/wiki/spaces/JCLARK/pages/14024804/API+-+Clark+WebApp#API-ClarkWebApp-getPageMeta
if (!window.clark.webapp.getPageMeta) {
  window.clark.webapp.getPageMeta = function () {
    return '{}';
  };
}

// @see https://clarkteam.atlassian.net/wiki/spaces/JCLARK/pages/14024804/API+-+Clark+WebApp#API-ClarkWebApp-show()show
if (!window.clark.webapp.show) {
  window.clark.webapp.show = function () {};
}

if (!window.clark.webapp.pushEnabled) {
  window.clark.webapp.pushEnabled = function (isEnabled) {
    window.localStorage.setItem('pushEnabled', isEnabled);
  };
}

if (!window.clark.runFunctionWhenReadyTimeout) {
  window.clark.runFunctionWhenReadyTimeout = {};
}

if (window.Capacitor && window.Capacitor.isNative) {
  window.clark.runFunctionWhenReady = function () {};
} else if (!window.clark.runFunctionWhenReady) {
  window.clark.runFunctionWhenReady = function (func, args) {
    // eslint-disable-next-line no-param-reassign
    args = args || [];
    // TODO: Add this back at some point
    // Have to comment because of EooR, initialization is later than before.
    // do nothing on non-ios devices
    // eslint-disable-next-line no-undef
    if ($('.body--web').length > 0) {
      return;
    }

    if (typeof window.clark.nativeapp !== 'undefined') {
      window.clearTimeout(window.clark.runFunctionWhenReadyTimeout[func]);
      if (window.clark.nativeapp[func]) {
        // call apply as we are now sending an array of arguments
        window.clark.nativeapp[func].apply(this, args);
      }
    } else {
      window.clark.runFunctionWhenReadyTimeout[func] = window.setTimeout(
        function () {
          window.clark.runFunctionWhenReady(func, args);
        },
        200,
      );
    }
  };
}
